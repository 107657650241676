import React from 'react'
import { useParams } from 'react-router-dom'
import NavBar from '../../atoms/NavBar'
import { Link, HStack, Container, Box, Heading, Text } from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import Card from '../../atoms/Card'
import DonationSelector from './DonationSelector'
import { useEventCashCollection } from '../Event'
import FundersList from './FundersList'
import { useEventContext } from '../../../contexts/EventProvider'
import GiftCardCarousel from './GiftCardCarousel'
import FooterBase from '../../molecules/FooterBase'
import loadable from '@loadable/component'
import { history } from '../../../../history'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import useCashCollectionGroup from '../../../hooks/useCashCollectionGroup'
import config from '../../../../../client/config.client'
import StyledExternalLink from '../../atoms/StyledExternalLink'

const ActionCard = loadable(() => import(/* webpackPrefetch: true */ './ActionCard'), { ssr: false })
const HeroImage = loadable(() => import(/* webpackPrefetch: true */ './HeroImage'), { ssr: false })

const Controls = () => {
	const { uuid } = useParams()

	return (
		<NavBar position="fixed">
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => history.push(`/event/${uuid}`)} />
			</HStack>
		</NavBar>
	)
}

const GiftCollection = () => {
	useScrollTopOnMount()
	const { event } = useEventContext()
	const { funders, amount } = useEventCashCollection(event)

	const [cashCollectionGroup, handleGroupChange] = useCashCollectionGroup(event)

	// temporarily redirect to tremendous catalog to get this out into prod asap
	// const handleRedirectToCatalog = () => navigate(`${root}/giftcards`)
	const handleRedirectCatalog = () => history.push('/giftcard/catalog')
	const giftCardCap = config.tremendous.eventCap / 100

	return (
		<>
			<Controls />

			<HeroImage maxH="180px" />

			<Container maxW="container.xl" pb="1rem" variant="main">
				<Box mb="2rem" textAlign="center" mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Add a Gift Card
					</Heading>
					<Text>
						Add up to ${giftCardCap} and the recipient can claim it as a{' '}
						<strong>gift card, prepaid Visa, or charitable donation</strong> of their choice. 🎁
					</Text>
				</Box>

				<ActionCard
					mx={['1rem', 0]}
					mb="2rem"
					group={cashCollectionGroup}
					handleGroupChange={() =>
						handleGroupChange(cashCollectionGroup == 'creator' ? 'everyone' : 'creator')
					}
				/>

				<Card
					alignItems="center"
					variant="main"
					mb="2rem"
					textAlign="center"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					<Box py="2rem">
						<Heading as="h3" size="md" mb="1rem">
							Add funds:
						</Heading>
						<DonationSelector />
						{amount > 0 && <FundersList collection={funders} role="creator" mt="2rem" />}
					</Box>
				</Card>

				<Box my="2rem" textAlign="center" mx={['1rem', 0]}>
					<Heading as="h3" size="md">
						Give the gift of choice.
					</Heading>

					<Text mb="1rem">
						There&apos;s something for everyone —{' '}
						<Link fontWeight="bold" color="var(--vidday-colors-link)" onClick={handleRedirectCatalog}>
							have a look
						</Link>{' '}
						at exact availability for your recipient&apos;s country.
					</Text>

					<GiftCardCarousel handleRedirect={handleRedirectCatalog} />
				</Box>

				<StyledExternalLink />

				<FooterBase />
			</Container>
		</>
	)
}

export default GiftCollection
