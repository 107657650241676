import React from 'react'
import { Box, Image, HStack, VStack, Text } from '@chakra-ui/react'
import { RedeemRounded } from '../../../atoms/Icon'

const cards = [
	'/assets/images/gift-card/card-target',
	'/assets/images/gift-card/card-uber',
	'/assets/images/gift-card/card-starbucks',
	'/assets/images/gift-card/card-home-depot',
	'/assets/images/gift-card/card-gamestop',
	'/assets/images/gift-card/card-best-buy',
]

const GiftCardCarousel = ({ handleRedirect }) => {
	return (
		<Box
			w="full"
			overflowX="auto"
			py="0.25rem"
			sx={{
				'scrollbarWidth': 'none',
				' msOverflowStyle': 'none',
				'&::-webkit-scrollbar': {
					display: 'none',
				},
			}}>
			<HStack justifyContent={['flex-start', 'center']}>
				{cards.map((path, i) => {
					const path2x = `${path}@2x.png`
					const path1x = `${path}.png`
					return (
						<Box key={i} boxShadow="base" borderRadius="lg" minW="106px">
							<Image src={path1x} srcSet={`${path2x} 2x, ${path1x} 1x`} width="106px" height="69px" />
						</Box>
					)
				})}

				<VStack
					w="106px"
					h="69px"
					minW="106px"
					bg="white"
					boxShadow="base"
					borderRadius="lg"
					spacing="0"
					justifyContent="center"
					cursor="pointer"
					onClick={handleRedirect}>
					<RedeemRounded color="#3587FF" />
					<Text
						cursor="pointer"
						color="#3587FF"
						fontWeight="bold"
						textTransform="uppercase"
						fontSize="0.75rem">
						See More
					</Text>
				</VStack>
			</HStack>
		</Box>
	)
}

export default GiftCardCarousel
